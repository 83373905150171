import { useCallback } from 'react'
import { useLocation, useRouteMatch } from 'react-router'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'reactstrap'

import { ThemeColor } from '../types/types'
import { getGoogleClientId } from '../utils/config'
import LinkTo from '../utils/links'

const Login = () => {
  const location = useLocation()
  const match = useRouteMatch(LinkTo.login())

  const getGoogleUrl = useCallback(() => {
    const getReturnTo = () => {
      if (match) {
        const found = location.search.match(/(?:\?|&)returnTo=(.+?)(?:&|$)/)
        if (found?.[1]) {
          return decodeURIComponent(found[1])
        }

        return LinkTo.root()
      }

      return (
        (location.pathname || LinkTo.root()) + location.search + location.hash
      )
    }

    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth'
    const params = [
      'scope=openid%20email',
      `state=${encodeURIComponent(getReturnTo())}`,
      'response_type=id_token',
      'nonce=1234',
      `client_id=${getGoogleClientId()}`,
    ]
    const siteUrl =
      window.location.protocol + '//' + window.location.host + LinkTo.login()

    params.push(`redirect_uri=${siteUrl}`)

    return `${baseUrl}?${params.join('&')}`
  }, [location.hash, location.pathname, location.search, match])

  const handleGoogle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()

      document.location.href = getGoogleUrl()
    },
    [getGoogleUrl]
  )

  return (
    <Row className="justify-content-center">
      <Col sm="10" md="8" lg="4">
        <Card>
          <CardHeader>
            <CardTitle>Log in</CardTitle>
          </CardHeader>
          <CardBody className="text-center">
            <CardText>Select your preferred log in method below.</CardText>
            <Button color={ThemeColor.primary} onClick={handleGoogle}>
              Log in with Google
            </Button>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Login
