import { getOperationName } from '@apollo/client/utilities'

// getRefetchQueries returns a list of operation names from given graphql-tag queries or a string.
export const getRefetchQueries = (queries: any[]): string[] | undefined => {
  const resolved = queries.map((query) => {
    const name = typeof query === 'string' ? query : getOperationName(query)
    if (!name) {
      throw new Error("getRefetchQueries: can't refetch unnamed queries")
    }
    return name
  })

  if (!resolved.length) {
    return undefined
  }

  return resolved
}
