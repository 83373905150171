interface LinkExternalProps extends React.HTMLProps<HTMLAnchorElement> {
  href: string // Normally it's optional, but let's make it required
}

const LinkExternal: React.FC<LinkExternalProps> = (props) => {
  // Set target and rel to some sane defaults for external linking.
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a target="_blank" rel="noopener noreferrer" {...props} />
}

export default LinkExternal
