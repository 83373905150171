import 'bootstrap/dist/css/bootstrap.css'
import './index.css'

import { ApolloProvider } from '@apollo/client'
import ReactDOM from 'react-dom'

import App from './App'
import { createApolloClient } from './utils/apollo'
import { setToken } from './utils/storage'

const main = () => {
  const { hash } = document.location
  if (hash) {
    const token = hash.match(/(?:#|&)id_token=(.+?)(?:&|$)/)
    if (token) {
      setToken(token[1])

      const state = hash.match(/(?:#|&)state=(.+?)(?:&|$)/)
      if (state) {
        window.location.href = decodeURIComponent(state[1])
        // Don't load the application while we're redirecting.
        return
      }
    }
  }

  const client = createApolloClient()

  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.getElementById('root')
  )
}

main()
