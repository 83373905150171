import { useState } from 'react'
import { Button } from 'reactstrap'

import { DeploymentsDocument, useSetImageMutation } from '../generated/graphql'
import { Environment, ThemeColor } from '../types/types'
import { getRefetchQueries } from '../utils/get-refetch-queries'
import LoadingAnimation from './LoadingAnimation'

interface Props {
  service: string
  env: Environment
  image: string
  tag: string
  color?: ThemeColor
  slackMessage?: string
  children: React.ReactNode
  className?: string
  disabled?: boolean
  afterSubmit?(): void
}

const DeployButton = ({
  service,
  env,
  image,
  tag,
  color,
  slackMessage,
  afterSubmit,
  className,
  disabled,
  children,
}: Props) => {
  const [isDeploying, setIsDeploying] = useState(false)

  const [submitRepository] = useSetImageMutation()

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    try {
      setIsDeploying(true)
      await submitRepository({
        refetchQueries: getRefetchQueries([DeploymentsDocument]),
        variables: {
          environmentName: env,
          deploymentName: service,
          containerName: service,
          image,
          tag,
          slackMessage,
        },
      })

      if (afterSubmit) {
        afterSubmit()
      }
      // Note we don't set `isDeploying` to `false` on success!
      // Button is removed once it has deployed.
    } catch (error) {
      // For now just alert with error message until we have proper flash support!
      alert(error.message)

      setIsDeploying(false)
    }
  }

  return (
    <Button
      className={className}
      color={
        color ||
        (env === Environment.production
          ? ThemeColor.primary
          : ThemeColor.secondary)
      }
      size="sm"
      onClick={onClick}
      disabled={isDeploying || disabled}
    >
      {isDeploying ? <LoadingAnimation>Deploying</LoadingAnimation> : children}
    </Button>
  )
}

export default DeployButton
