import './App.css'
import 'tippy.js/dist/tippy.css'

import {
  BrowserRouter as Router,
  Link,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom'
import { Container } from 'reactstrap'

import Deployment from './pages/Deployment'
import Deployments from './pages/Deployments'
import DeploymentTesting from './pages/DeploymentTesting'
import Login from './pages/Login'
import Logout from './pages/Logout'
import NotFound from './pages/NotFound'
import Root from './pages/Root'
import LinkTo from './utils/links'
import { getToken } from './utils/storage'

const App = () => {
  return (
    <Container className="App">
      <Router>
        <div>
          {getToken() && (
            <div className="text-right">
              <Link to={LinkTo.logout()}>Log out</Link>
            </div>
          )}

          <Switch>
            <PrivateRoute exact path={LinkTo.root()} component={Root} />

            <PrivateRoute
              exact
              path={LinkTo.deployments()}
              component={Deployments}
            />
            <PrivateRoute
              exact
              path={LinkTo.deployment()}
              component={Deployment}
            />
            <PrivateRoute
              exact
              path={LinkTo.deploymentTesting()}
              component={DeploymentTesting}
            />

            <Route path={LinkTo.logout()} component={Logout} />
            <Route path={LinkTo.login()} component={Login} />

            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    </Container>
  )
}

export const PrivateRoute = (props: RouteProps) => {
  if (!getToken()) {
    return <Login />
  }

  return <Route {...props} />
}

export default App
