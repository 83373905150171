export enum Environment {
  testing = 'testing',
  staging = 'staging',
  production = 'production',
  preproduction = 'preproduction',
}

export enum ThemeColor {
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  light = 'light',
  dark = 'dark',
  white = 'white',
}
