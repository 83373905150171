import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import IntrospectionResultData from '../generated/possible-types'
import { getAPIEndpoint } from './config'
import LinkTo from './links'
import { getToken, setToken } from './storage'

export const createApolloClient = () => {
  const httpLink = createHttpLink({
    uri: getAPIEndpoint(),
  })

  const authLink = setContext((_, { headers }) => {
    const token = getToken()

    if (token) {
      headers = {
        ...headers,
        authorization: `Bearer ${token}`,
      }
    }

    return {
      headers,
    }
  })

  const errorLink = onError((errorHandler) => {
    if ((errorHandler?.networkError as any)?.statusCode === 401) {
      setToken()
      document.location.href = getLoginPath(document.location)
    }
  })

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      possibleTypes: IntrospectionResultData.possibleTypes,
    }),
  })

  return client
}

const getLoginPath = (location: Location) => {
  if (
    location.pathname !== LinkTo.root() &&
    location.pathname !== LinkTo.login() &&
    location.pathname !== LinkTo.logout()
  ) {
    let returnTo = location.pathname
    if (location.search) {
      returnTo += location.search
    }
    if (location.hash) {
      returnTo += location.hash
    }

    return LinkTo.login(returnTo)
  }

  return LinkTo.login()
}
