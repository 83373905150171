export const getGithubUrl = (serviceName: string) => {
  const name = serviceName === 'data-quality-v2' ? 'data-quality' : serviceName
  return `https://github.com/connectedcars/${name}`
}

export const getGithubDiffUrl = (
  serviceName: string,
  oldTag: string,
  newTag: string
) => {
  return `${getGithubUrl(serviceName)}/compare/${oldTag}...${newTag}`
}
