import { useState } from 'react'
import { Button, Form, Input } from 'reactstrap'

import LoadingAnimation from './LoadingAnimation'

interface BlockDeploymentFormProps {
  onSubmit(message: string): void
}

const BlockDeploymentForm: React.FC<BlockDeploymentFormProps> = ({
  onSubmit,
}) => {
  const [blockMessage, setBlockMessage] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (blockMessage === null) {
    return (
      <Button color="secondary" onClick={() => setBlockMessage('')} size="sm">
        Add block deployment alert
      </Button>
    )
  }

  return (
    <Form
      inline
      className="mb-0 d-flex"
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(blockMessage)
        setIsSubmitting(true)
      }}
    >
      <Input
        disabled={isSubmitting}
        value={blockMessage || ''}
        onChange={(e) => setBlockMessage(e.currentTarget.value)}
        placeholder="Write why you want to block deployment"
        className="mr-2 flex-grow-1"
        bsSize="sm"
      />
      <Button
        color="primary"
        className="mr-2"
        size="sm"
        type="submit"
        disabled={!blockMessage || isSubmitting}
      >
        {isSubmitting ? (
          <LoadingAnimation>Saving</LoadingAnimation>
        ) : (
          'Save alert'
        )}
      </Button>
      <Button
        color="secondary"
        size="sm"
        type="button"
        disabled={isSubmitting}
        onClick={() => setBlockMessage(null)}
      >
        Cancel
      </Button>
    </Form>
  )
}

export default BlockDeploymentForm
