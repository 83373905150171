import { useState } from 'react'
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import { Environment, ThemeColor } from '../types/types'
import DeployButton from './DeployButton'
import LinkExternal from './LinkExternal'

interface SlackUrlProps {
  environment: Environment
}

const SlackUrl: React.FC<SlackUrlProps> = ({ environment }) => {
  if (environment === Environment.production) {
    return (
      <LinkExternal href="https://connectedcarssemler.slack.com/archives/C7B903T7S">
        #changelog
      </LinkExternal>
    )
  }

  if (environment === Environment.staging) {
    return (
      <LinkExternal href="https://connectedcarssemler.slack.com/archives/C9N5FJLHL">
        #changelog-staging
      </LinkExternal>
    )
  }

  return null
}

export interface SlackInfo {
  serviceName: string
  env: Environment
  serviceImage: string
  imageTag: string
  slackMessage: string
  color: ThemeColor
}

interface SlackPostAddProps {
  isOpen: boolean
  onClose(): void
  slackInfo: SlackInfo
}

const SlackPostAdd: React.FC<SlackPostAddProps> = ({
  isOpen,
  onClose,
  slackInfo,
}) => {
  const [slackMessage, setSlackMessage] = useState(slackInfo.slackMessage)
  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" backdrop>
      <ModalHeader toggle={onClose}>
        Post to <SlackUrl environment={slackInfo.env} /> on Slack?
      </ModalHeader>
      <ModalBody>
        <Input
          rows="8"
          type="textarea"
          value={slackMessage}
          onChange={(e) => setSlackMessage(e.currentTarget.value)}
        />
      </ModalBody>
      <ModalFooter>
        <DeployButton
          color={ThemeColor.white}
          service={slackInfo.serviceName}
          env={slackInfo.env}
          image={slackInfo.serviceImage}
          tag={slackInfo.imageTag}
          afterSubmit={onClose}
        >
          Skip and deploy to {slackInfo.env}
        </DeployButton>
        <DeployButton
          color={slackInfo.color}
          service={slackInfo.serviceName}
          env={slackInfo.env}
          image={slackInfo.serviceImage}
          tag={slackInfo.imageTag}
          slackMessage={slackMessage}
          afterSubmit={onClose}
          disabled={slackMessage === ''}
        >
          Post and deploy to {slackInfo.env}
        </DeployButton>
      </ModalFooter>
    </Modal>
  )
}

export default SlackPostAdd
