import moment from 'moment'
import { useCallback, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Badge, Button, Container, Progress, Table } from 'reactstrap'
import styled from 'styled-components'

import Avatar from '../components/Avatar'
import BlockDeploymentAlert from '../components/BlockDeploymentAlert'
import BlockDeploymentForm from '../components/BlockDeploymentForm'
import CompareImagesLink from '../components/CompareImagesLink'
import DeployButton from '../components/DeployButton'
import Error from '../components/Error'
import LinkExternal from '../components/LinkExternal'
import SlackPostAdd, { SlackInfo } from '../components/ModalSlackPostAdd'
import Tooltip from '../components/Tooltip'
import {
  IBlock,
  IDeploymentsQuery,
  Maybe,
  useBlockDeploymentMutation,
  useDeploymentsQuery,
} from '../generated/graphql'
import { Environment, ThemeColor } from '../types/types'
import { getGithubUrl } from '../utils/github'
import LinkTo from '../utils/links'

const Deployment = () => {
  const { name } = useParams<{ name: string }>()
  const [slackInfo, setSlackInfo] = useState<SlackInfo | null>(null)

  const { data, error, loading } = useDeploymentsQuery({
    pollInterval: 5000,
  })

  const [submitBlockDeployment] = useBlockDeploymentMutation()

  const handleSubmitBlockMessage = useCallback(
    async (message: string | null) => {
      try {
        await submitBlockDeployment({
          variables: {
            deploymentName: name,
            message,
            block: !!message,
          },
        })
      } catch (err) {}
    },
    [name, submitBlockDeployment]
  )

  if (error) {
    return <Error error={error} />
  }

  if (loading || !data?.infrastructure) {
    return (
      <Container className="App">
        <Progress animated striped value={100} />
      </Container>
    )
  }

  const service = getService(data, name)

  const deployOrder = [
    // Don't include testing as it's a special case. We always want to be
    // able to deploy something regardless of it having testing or not.
    // We also don't want to deploy something to testing before staging, etc.
    service.staging && Environment.staging,
    service.preproduction && Environment.preproduction,
    service.production && Environment.production,
  ].filter(Boolean) as Environment[]

  const afterDeployOrder: Environment[] = []

  const earliestEnvironment = deployOrder[0]
  if (!earliestEnvironment) {
    return <div>No environments available</div>
  }

  const earliestService = service[earliestEnvironment]

  // Only return images that have been deployed in the earliest/first environment.
  const images = getImages(data, earliestService?.image)

  return (
    <div>
      {(slackInfo?.env === Environment.staging ||
        slackInfo?.env === Environment.production) && (
        <SlackPostAdd
          slackInfo={slackInfo}
          isOpen={!!slackInfo}
          onClose={() => setSlackInfo(null)}
        />
      )}
      <div className="mb-3">
        <h1>
          <Link to={LinkTo.deployments()}>Deployments</Link> / {service.name}
        </h1>

        <div className="d-flex align-items-center justify-content-between">
          <span>
            <a href={getGithubUrl(service.name)}>Github repository</a>
            {deployOrder.length > 0 && (
              <div>
                <small>
                  Environments ({deployOrder.length}):{' '}
                  {deployOrder.join(' → ') || 'N/A'}
                </small>
              </div>
            )}
          </span>
        </div>
      </div>

      <div className="mb-4">
        {service.block?.isBlocked ? (
          <BlockDeploymentAlert
            message={service.block?.message || ''}
            email={service.block.email || ''}
            time={service.block.blockedAt}
            onSubmit={handleSubmitBlockMessage}
          />
        ) : (
          <BlockDeploymentForm onSubmit={handleSubmitBlockMessage} />
        )}
      </div>

      <div>
        <ul className="nav nav-tabs border-bottom-0">
          <li className="nav-item">
            <Link
              className="nav-link active"
              to={LinkTo.deployment(service.name)}
            >
              Master
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to={LinkTo.deploymentTesting(service.name)}
            >
              Testing
            </Link>
          </li>
        </ul>
      </div>

      <StyledTable>
        <thead>
          <tr>
            <th />
            <th>User</th>
            <th>Image</th>
            <th>Time</th>
            <th>Compare</th>
            <th>
              Action{' '}
              {service.updateStatus === 'progressing' && (
                <Badge color={ThemeColor.warning}>Updating</Badge>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {images.map((image, imageIndex) => {
            if (!image.tag) {
              return null
            }

            const currentDeploymentIn: Environment[] = []

            if (image.tag === service.testing?.tag) {
              currentDeploymentIn.push(Environment.testing)
              // Testing is special case, so don't push it into the `afterDeployOrder`
            }

            if (image.tag === service.staging?.tag) {
              currentDeploymentIn.push(Environment.staging)
              afterDeployOrder.push(Environment.staging)
            }

            if (image.tag === service.preproduction?.tag) {
              currentDeploymentIn.push(Environment.preproduction)
              afterDeployOrder.push(Environment.preproduction)
            }

            if (image.tag === service.production?.tag) {
              currentDeploymentIn.push(Environment.production)
              afterDeployOrder.push(Environment.production)
            }

            const filteredDeployOrder = deployOrder.filter((v) => {
              // Filter out things already deployed to this environment.
              if (currentDeploymentIn.includes(v)) {
                return false
              }

              // Filter out all deploys that are not after current highest.
              const nextDeployOffset =
                deployOrder.indexOf(
                  afterDeployOrder[afterDeployOrder.length - 1]
                ) + 1
              if (nextDeployOffset < deployOrder.length) {
                if (nextDeployOffset !== deployOrder.indexOf(v)) {
                  return false
                }
              }

              return true
            })

            return (
              <tr
                key={image.digest}
                className={currentDeploymentIn.length ? 'font-weight-bold' : ''}
              >
                <td style={{ width: 1 }}>
                  {currentDeploymentIn.includes(Environment.production) && (
                    <div>
                      <Badge color={ThemeColor.success}>Production</Badge>
                    </div>
                  )}
                  {currentDeploymentIn.includes(Environment.preproduction) && (
                    <div>
                      <Badge color={ThemeColor.info}>Preproduction</Badge>
                    </div>
                  )}
                  {currentDeploymentIn.includes(Environment.staging) && (
                    <div>
                      <Badge color={ThemeColor.warning}>Staging</Badge>
                    </div>
                  )}
                </td>
                <td style={{ width: 1 }}>
                  <Avatar
                    fullName={image.commit?.fullName}
                    avatarUrl={image.commit?.avatarUrl}
                    username={image.commit?.username}
                  />
                </td>
                <td>
                  <Tooltip placement="bottom" content={image.commit?.message}>
                    <ImageContainer>
                      {image.commit?.prNumber ? (
                        <LinkExternal
                          className="mr-2"
                          href={`${getGithubUrl(service.name)}/pull/${
                            image.commit.prNumber
                          }/files`}
                        >{`#${image.commit.prNumber}`}</LinkExternal>
                      ) : image.tag ? (
                        <LinkExternal
                          className="mr-2"
                          href={`${getGithubUrl(service.name)}/commit/${
                            image.tag
                          }`}
                        >
                          {image.tag.substr(0, 7)}
                        </LinkExternal>
                      ) : null}
                      {image.commit?.message}
                    </ImageContainer>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip
                    content={
                      <div>
                        {moment(image.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    }
                  >
                    <span>{moment(image.createdAt).fromNow()}</span>
                  </Tooltip>
                </td>
                <td>
                  {earliestService?.tag &&
                    earliestService.tag !== image.tag && (
                      <CompareImagesLink
                        service={service.name}
                        tags={[earliestService.tag, image.tag]}
                        reversed={afterDeployOrder.includes(
                          earliestEnvironment
                        )}
                      />
                    )}
                </td>
                <td
                  className="text-right"
                  style={{ width: 1, whiteSpace: 'nowrap' }}
                >
                  {filteredDeployOrder.map((env) => {
                    const serviceImage = service[env]?.image
                    if (!serviceImage || !image.tag) {
                      return null
                    }

                    let color = ThemeColor.primary
                    // Mark danger on highest level deployment
                    if (deployOrder.indexOf(env) === deployOrder.length) {
                      color = ThemeColor.danger
                    }
                    // Mark secondary on rollbacks
                    if (afterDeployOrder.includes(env)) {
                      color = ThemeColor.secondary
                    }

                    const activeImage = images.findIndex(
                      (image) => image.tag === service[env]?.tag
                    )

                    if (
                      (env === 'production' || env === 'staging') &&
                      activeImage > imageIndex
                    ) {
                      return (
                        <div key={env}>
                          <Button
                            className="mb-1"
                            size="sm"
                            color={color}
                            onClick={() => {
                              let slackMessage = ''
                              if (activeImage > imageIndex) {
                                const diffImages = [...images]
                                  .splice(imageIndex, activeImage - imageIndex)
                                  .filter((image) => image.commit?.message)
                                  .reverse()

                                diffImages.forEach((image, index) => {
                                  slackMessage += `- ${image.commit?.message}`

                                  // Add newline unless it's the last
                                  if (index !== diffImages.length - 1) {
                                    slackMessage += `\r\n`
                                  }
                                })
                              }
                              setSlackInfo({
                                serviceName: service.name,
                                env,
                                serviceImage,
                                imageTag: image.tag || '',
                                slackMessage,
                                color,
                              })
                            }}
                          >
                            Deploy to {env}
                          </Button>
                        </div>
                      )
                    }

                    return (
                      <div key={env}>
                        <DeployButton
                          className="mb-1"
                          service={service.name}
                          color={color}
                          env={env}
                          image={serviceImage}
                          tag={image.tag}
                        >
                          Deploy to {env}
                        </DeployButton>
                      </div>
                    )
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    </div>
  )
}

interface Service {
  name: string
  updateStatus: Maybe<string>
  testing: Maybe<ServiceDeployment>
  staging: Maybe<ServiceDeployment>
  production: Maybe<ServiceDeployment>
  preproduction: Maybe<ServiceDeployment>
  block: Maybe<IBlock>
}

interface ServiceDeployment {
  name: Maybe<string>
  image: Maybe<string>
  tag: Maybe<string>
}

const getService = (data: IDeploymentsQuery, name: string) => {
  const service: Service = {
    name,
    updateStatus: null,
    testing: null,
    staging: null,
    production: null,
    preproduction: null,
    block: null,
  }

  const environments = data?.infrastructure?.environments
  if (!environments) {
    return service
  }

  for (const environment of environments) {
    if (!environment?.deployments) {
      continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
    }
    for (const deployment of environment.deployments) {
      if (!deployment) {
        continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
      }
      if (deployment.name === name) {
        service.updateStatus = deployment.updateStatus || service.updateStatus
        service.block = deployment.block

        if (!deployment.containers) {
          continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
        }
        for (const container of deployment.containers) {
          if (!container) {
            continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
          }
          if (container.name === name) {
            switch (environment.name) {
              case Environment.staging:
              case Environment.production:
              case Environment.preproduction:
              case Environment.testing:
                service[environment.name] = container
                break
              default:
                break
            }
          }
        }
      }
    }
  }
  return service
}

const getImages = (data: IDeploymentsQuery, image: Maybe<string>) => {
  if (!data?.infrastructure?.images) {
    // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
    return []
  }

  const { images } = data.infrastructure

  for (const deployment of images) {
    if (!deployment?.images) {
      continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
    }
    if (image === deployment.name) {
      return deployment.images.map((v) => {
        // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
        return {
          digest: v?.digest,
          tag: v?.tags?.filter((tag) => tag !== 'latest').shift(),
          size: v?.size,
          createdAt: v?.createdAt,
          uploadedAt: v?.uploadedAt,
          commit: v?.commit,
        }
      })
    }
  }
  return []
}

const StyledTable = styled(Table)`
  td {
    vertical-align: middle;
    white-space: nowrap;
  }
`

const ImageContainer = styled.div`
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default Deployment
