import { Alert } from 'reactstrap'

import { ThemeColor } from '../types/types'

interface Props {
  error: Error | string
}

const Error = ({ error }: Props) => {
  if (!error) {
    return null
  }

  if (typeof error === 'string') {
    return <Alert color={ThemeColor.danger}>{error}</Alert>
  }

  if (error.message.match(/403/)) {
    return (
      <Alert color={ThemeColor.warning}>
        You do not have the necessary permissions!
      </Alert>
    )
  }

  return <Alert color={ThemeColor.danger}>{error.message}</Alert>
}

export default Error
