import { getGithubDiffUrl } from '../utils/github'

interface Props {
  service: string
  tags: [string, string]
  reversed?: boolean
}

const CompareImagesLink = ({ service, tags, reversed }: Props) => {
  if (reversed) {
    tags.reverse()
  }

  const [first, last] = tags
  return (
    <a href={getGithubDiffUrl(service, first, last)}>
      {first.substr(0, 7)}...{last.substr(0, 7)}
    </a>
  )
}

export default CompareImagesLink
