import moment from 'moment'
import { useState } from 'react'
import { Alert, Button } from 'reactstrap'

import LoadingAnimation from './LoadingAnimation'
import Tooltip from './Tooltip'

interface BlockDeploymentAlertProps {
  message: string
  email: string
  time?: string | null
  onSubmit(message: string | null): void
}

const BlockDeploymentAlert: React.FC<BlockDeploymentAlertProps> = ({
  message,
  email,
  time,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Alert
      color="danger"
      className="d-flex align-items-center justify-content-between"
    >
      <span>
        {`${email}: ${message}`}
        {time && (
          <span className="ml-2">
            (
            <Tooltip
              content={<div>{moment(time).format('YYYY-MM-DD HH:mm:ss')}</div>}
            >
              <span>{moment(time).fromNow()}</span>
            </Tooltip>
            )
          </span>
        )}
      </span>
      <span>
        <Button
          className="ml-2"
          color="danger"
          size="sm"
          disabled={isSubmitting}
          onClick={() => {
            onSubmit(null)
            setIsSubmitting(true)
          }}
        >
          {isSubmitting ? (
            <LoadingAnimation>Removing alert</LoadingAnimation>
          ) : (
            'Remove alert'
          )}
        </Button>
      </span>
    </Alert>
  )
}

export default BlockDeploymentAlert
