const TOKEN_COOKIE = 'buildstatusAccessToken'

export const getToken = () => {
  return localStorage.getItem(TOKEN_COOKIE)
}

export const setToken = (token?: string | null) => {
  if (!token) {
    localStorage.removeItem(TOKEN_COOKIE)
  } else {
    localStorage.setItem(TOKEN_COOKIE, token)
  }
}
