import { Environment } from '../types/types'

const getEnvironment = () => {
  return Environment.production
}

export const getGoogleClientId = () => {
  switch (getEnvironment()) {
    case Environment.production:
    case Environment.staging:
    case Environment.testing:
    default:
      return '807025168921-toli6he7c3klte0bsvq8sk4p3evan6c6.apps.googleusercontent.com'
  }
}

export const getAPIEndpoint = () => {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL
  }
  switch (getEnvironment()) {
    case Environment.production:
      return 'https://buildstatus-api.connectedcars.io/graphql'
    case Environment.staging:
      return 'https://buildstatus-api.staging.connectedcars.io/graphql'
    case Environment.testing:
      return 'https://buildstatus-api.testing.connectedcars.io/graphql'
    default:
      return 'http://localhost:3333/graphql'
  }
}
