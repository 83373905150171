import styled from 'styled-components'

import NoAvatar from '../assets/no-avatar.png'
import Tooltip from './Tooltip'

interface AvatarProps {
  fullName?: string | null
  avatarUrl?: string | null
  username?: string | null
}

const Avatar: React.FC<AvatarProps> = ({ fullName, avatarUrl, username }) => {
  if (fullName || username) {
    return (
      <>
        <Tooltip
          theme="dark"
          placement="right"
          arrow
          content={<div>{[username, fullName].join(': ')}</div>}
        >
          <span>
            <StyledAvatar src={avatarUrl || NoAvatar} />
          </span>
        </Tooltip>
      </>
    )
  }
  return <StyledAvatar src={avatarUrl || NoAvatar} />
}

const StyledAvatar = styled.img`
  user-select: none;
  height: 30px;
  width: 30px;
  border-radius: 50%;
`

export default Avatar
