const LinkTo = {
  root: () => '/',

  // Authentication
  login: (returnTo?: string) => '/login' + serialize({ returnTo }),
  logout: () => '/logout',

  // Deployments
  deployments: () => '/deployments',
  deployment: (name?: string) => replace('/deployments/:name', { name }),
  deploymentTesting: (name?: string) =>
    replace('/deployments/:name/testing', { name }),
}

export const replace = (path: string, values?: Record<string, any>): string => {
  if (values) {
    for (const key of Object.keys(values)) {
      const value = values[key]
      if (value != null) {
        path = path.replace(':' + key, value)
      }
    }
  }
  return path
}

export const serialize = (
  obj?: Record<string, any> | null,
  prefix = '?'
): string => {
  if (obj == null) {
    return ''
  }
  const str = []
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      // keys that start with $ will not be encoded
      const encode = p.startsWith('$')
      const val = obj[p]
      if (val != null) {
        if (encode) {
          str.push(`${p.substring(1)}=${val}`)
        } else {
          str.push(`${encodeURIComponent(p)}=${encodeURIComponent(val)}`)
        }
      }
    }
  }
  return str.length > 0 ? prefix + str.join('&') : ''
}

export default LinkTo
