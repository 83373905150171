import { Link } from 'react-router-dom'
import { Badge, Progress, Table } from 'reactstrap'

import DeployButton from '../components/DeployButton'
import Error from '../components/Error'
import LinkExternal from '../components/LinkExternal'
import Star, { getStars } from '../components/Star'
import {
  IContainer,
  IDeploymentsQuery,
  Maybe,
  useDeploymentsQuery,
} from '../generated/graphql'
import { Environment, ThemeColor } from '../types/types'
import { getGithubUrl } from '../utils/github'
import LinkTo from '../utils/links'
import { isEnvironment } from '../utils/type-guards'

const Deployments = () => {
  const stars = getStars()
  const { data, loading, error } = useDeploymentsQuery({
    pollInterval: 5000,
  })

  if (error) {
    return <Error error={error} />
  }
  if (loading) {
    return <Progress animated striped value={100} />
  }

  if (!data?.infrastructure) {
    return <Error error="No data returned from API" />
  }

  const deployments = getDeployments(data).sort((a, b) => {
    return (stars.includes(b.name) ? 1 : 0) - (stars.includes(a.name) ? 1 : 0) // Starred deployments first
  })

  return (
    <div>
      <h1 className="mb-5">Deployments</h1>
      {new Date().getDay() === 5 && <Error error="It's Friday!" />}
      <Table>
        <thead>
          <tr>
            <th />
            <th>Name</th>
            <th>Testing</th>
            <th>Staging</th>
            <th>Preproduction</th>
            <th>Production</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {deployments.map((service) => {
            let productionImage = null
            let productionTag = null
            if (service.env.production.container) {
              productionImage = service.env.production.container.image
              productionTag = service.env.production.container.tag
            }
            const testingDeploys = service.env.testing.containers.length

            return (
              <tr key={service.name}>
                <td>
                  <Star id={service.name} />
                </td>
                <td>
                  <Link to={LinkTo.deployment(service.name)}>
                    {service.name}
                  </Link>
                </td>
                <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                  {!testingDeploys ? (
                    <span className="text-muted">Not deployed</span>
                  ) : (
                    <Link to={LinkTo.deploymentTesting(service.name)}>
                      {testingDeploys} deploys
                    </Link>
                  )}
                </td>
                <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                  {getDescription(service, Environment.staging)}
                </td>
                <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                  {getDescription(service, Environment.preproduction)}
                </td>
                <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                  {getDescription(service, Environment.production)}
                </td>
                <td style={{ width: 1, whiteSpace: 'nowrap' }}>
                  {productionTag && productionImage && (
                    <DeployButton
                      service={service.name}
                      env={Environment.production}
                      image={productionImage}
                      tag={productionTag}
                      color={ThemeColor.secondary}
                    >
                      Redeploy
                    </DeployButton>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

interface Deployment {
  name: string
  env: Record<
    Environment,
    {
      generation: Maybe<number>
      updateStatus: Maybe<string>
      container: Maybe<IContainer>
      containers: Array<Maybe<IContainer>>
    }
  >
}

type DeploymentsMap = Record<string, Deployment>

const getDeployments = (data: IDeploymentsQuery): Deployment[] => {
  const environments = data?.infrastructure?.environments
  if (!environments) {
    return []
  }

  const deployments: DeploymentsMap = {}
  for (const environment of environments) {
    if (!environment?.deployments) {
      continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
    }
    for (const deployment of environment.deployments) {
      if (!deployment?.name) {
        continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
      }
      if (!deployments[deployment.name]) {
        deployments[deployment.name] = {
          name: deployment.name,
          env: {
            staging: {
              generation: null,
              updateStatus: null,
              container: null,
              containers: [],
            },
            production: {
              generation: null,
              updateStatus: null,
              container: null,
              containers: [],
            },
            preproduction: {
              generation: null,
              updateStatus: null,
              container: null,
              containers: [],
            },
            testing: {
              generation: null,
              updateStatus: null,
              container: null,
              containers: [],
            },
          },
        }
      }

      if (!deployment.containers || !isEnvironment(environment.name)) {
        continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
      }

      deployments[deployment.name].env[environment.name] = {
        generation: deployment.generation,
        updateStatus: deployment.updateStatus,
        container: deployment.containers
          .filter((container) => container?.name === deployment.name)
          .shift(),
        containers: deployments[deployment.name].env[
          environment.name
        ].containers.concat(
          deployment.containers.filter(
            (container) => container?.name === deployment.name
          )
        ),
      }
    }
  }

  const items = Object.values(deployments)
  items.sort((a, b) => a.name.localeCompare(b.name))
  return items
}

const getDescription = (
  service: Deployment,
  env: Environment,
  multiple = false
) => {
  if (!service.env[env].container) {
    return <span className="text-muted">Not deployed</span>
  }
  const { updateStatus, generation, container, containers } = service.env[env]
  if (!container?.tag) {
    return null // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
  }
  return (
    <span>
      {updateStatus === 'progressing' && (
        <Badge color={ThemeColor.warning}>Updating</Badge>
      )}{' '}
      {multiple ? (
        containers.map((container) => {
          if (!container?.tag) {
            return null // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
          }
          return (
            <div key={container.tag}>
              <LinkExternal
                href={`${getGithubUrl(service.name)}/commit/${container.tag}`}
              >
                {container.tag.substr(0, 7)}
              </LinkExternal>
            </div>
          )
        })
      ) : (
        <div>
          <LinkExternal
            href={`${getGithubUrl(service.name)}/commit/${container.tag}`}
          >
            {container.tag.substr(0, 7)}
          </LinkExternal>{' '}
          (#{generation})
        </div>
      )}
    </span>
  )
}

export default Deployments
