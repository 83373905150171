import { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import LinkTo from '../utils/links'
import { setToken } from '../utils/storage'

const Logout = () => {
  useEffect(() => {
    setToken()
  }, [])

  return <Redirect to={LinkTo.login()} />
}

export default Logout
