const STAR_STORAGE_KEY = 'buildstatusStarToken'

interface Props {
  id: string
}

const Star = ({ id }: Props) => {
  const stars = getStars()
  const isStarred = stars.includes(id)

  if (isStarred) {
    return (
      <span
        className="text-warning cursor-pointer"
        onClick={() => removeStar(id)}
      >
        ★
      </span>
    )
  }

  return (
    <span className="cursor-pointer" onClick={() => setStar(id)}>
      ☆
    </span>
  )
}

export const getStars = (): string[] => {
  const stars = localStorage.getItem(STAR_STORAGE_KEY)

  if (stars) {
    try {
      const data = JSON.parse(stars)
      if (Array.isArray(data)) {
        return data
      }
      throw new Error('starred items not an array')
    } catch (err) {
      console.error(err)
      localStorage.removeItem(STAR_STORAGE_KEY)
    }
  }

  return []
}

const setStar = (id: string) => {
  const stars = getStars()

  // Star already set
  if (stars && stars.includes(id)) {
    return
  }

  stars.push(id)
  localStorage.setItem(STAR_STORAGE_KEY, JSON.stringify(stars))
  window.location.reload()
}

const removeStar = (id: string) => {
  const stars = getStars().filter((s) => s !== id)
  localStorage.setItem(STAR_STORAGE_KEY, JSON.stringify(stars))
  window.location.reload()
}

export default Star
