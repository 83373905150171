import Tippy, { TippyProps } from '@tippyjs/react'

interface TooltipProps extends TippyProps {}

const Tooltip: React.FC<TooltipProps> = ({ content, children, ...rest }) => {
  if (!content) {
    return <>{children}</>
  }

  return (
    <Tippy theme="dark" placement="right" content={content} arrow {...rest}>
      {children}
    </Tippy>
  )
}

export default Tooltip
