import { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Progress, Table } from 'reactstrap'

import Error from '../components/Error'
import LinkExternal from '../components/LinkExternal'
import { useDeploymentsQuery } from '../generated/graphql'
import { Environment } from '../types/types'
import { getGithubUrl } from '../utils/github'
import LinkTo from '../utils/links'

const DeploymentTesting = () => {
  const { name } = useParams<{ name: string }>()
  const { data, loading, error } = useDeploymentsQuery({
    pollInterval: 5000,
  })

  const serviceName = name.toLowerCase()

  const deployments = useMemo(() => {
    const testing = data?.infrastructure?.environments?.find(
      (v) => v?.name === Environment.testing
    )
    if (!testing?.deployments) {
      return []
    }

    const deploys = []
    for (const deployment of testing.deployments) {
      if (deployment?.name !== name) {
        continue // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
      }

      deploys.push(deployment)
    }

    return deploys
  }, [data?.infrastructure?.environments, name])

  if (error) {
    return <Error error={error} />
  }

  if (loading) {
    return <Progress animated striped value={100} />
  }

  if (!data) {
    return <Error error="No data returned from API" />
  }

  if (!deployments) {
    return <Error error="No service found" />
  }

  return (
    <div>
      <div className="mb-5">
        <h1>
          <Link to={LinkTo.deployments()}>Deployments</Link> / {serviceName}
        </h1>
        <a href={getGithubUrl(serviceName)}>Github repository</a>
      </div>

      <div>
        <ul className="nav nav-tabs border-bottom-0">
          <li className="nav-item">
            <Link className="nav-link" to={LinkTo.deployment(serviceName)}>
              Master
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link active"
              to={LinkTo.deploymentTesting(serviceName)}
            >
              Testing
            </Link>
          </li>
        </ul>
      </div>

      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Tag</th>
          </tr>
        </thead>
        <tbody>
          {!deployments.length && (
            <tr>
              <td colSpan={2}>No deployments</td>
            </tr>
          )}
          {deployments.map((deployment) => {
            // Assume the first container in the list is the only relevant one.
            const [container] = deployment.containers || []
            if (
              !container ||
              !container.name ||
              !container.image ||
              !container.tag
            ) {
              return null // TODO: https://app.clubhouse.io/connectedcars/story/37693/fix-types-to-be-non-nullable
            }

            const { branch } = getImageInfo(container.image)
            return (
              <tr key={container.tag}>
                <td>{branch}</td>
                <td>
                  <LinkExternal
                    href={`${getGithubUrl(container.name)}/commit/${
                      container.tag
                    }`}
                  >
                    {container.tag.substr(0, 7)}
                  </LinkExternal>{' '}
                  (#{deployment.generation})
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

const getImageInfo = (image: string) => {
  // Example deployment image:
  // gcr.io/connectedcars-staging/manage.feature-ch41856-filter-out-trips-with-missing-data-for-trip

  const parts = image.split('/')

  const [repo, branch] = parts[parts.length - 1].split('.', 2)

  return {
    repo,
    branch,
  }
}

export default DeploymentTesting
