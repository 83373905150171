// AUTOMATICALLY GENERATED FILE - DO NOT EDIT
/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ID = Scalars['ID']
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type IQuery = {
  readonly __typename: 'Query';
  readonly infrastructure: Maybe<IInfrastructure>;
};

export type IInfrastructure = {
  readonly __typename: 'Infrastructure';
  readonly updateAt: Maybe<Scalars['String']>;
  readonly environments: Maybe<ReadonlyArray<Maybe<IEnvironment>>>;
  readonly images: Maybe<ReadonlyArray<Maybe<IImageNamespace>>>;
};

export type IEnvironment = {
  readonly __typename: 'Environment';
  readonly name: Maybe<Scalars['String']>;
  readonly deployments: Maybe<ReadonlyArray<Maybe<IDeployment>>>;
};

export type IDeployment = {
  readonly __typename: 'Deployment';
  readonly name: Maybe<Scalars['String']>;
  readonly user: Maybe<Scalars['String']>;
  readonly createdAt: Maybe<Scalars['String']>;
  readonly updateAt: Maybe<Scalars['String']>;
  readonly containers: Maybe<ReadonlyArray<Maybe<IContainer>>>;
  readonly generation: Maybe<Scalars['Int']>;
  readonly updateStatus: Maybe<Scalars['String']>;
  readonly featureDeployment: Maybe<Scalars['Boolean']>;
  readonly branchName: Maybe<Scalars['String']>;
  readonly namespace: Maybe<Scalars['String']>;
  readonly block: Maybe<IBlock>;
};

export type IContainer = {
  readonly __typename: 'Container';
  readonly image: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
  readonly tag: Maybe<Scalars['String']>;
};

export type IBlock = {
  readonly __typename: 'Block';
  readonly email: Maybe<Scalars['String']>;
  readonly blockedAt: Maybe<Scalars['String']>;
  readonly isBlocked: Maybe<Scalars['Boolean']>;
  readonly message: Maybe<Scalars['String']>;
};

export type IImageNamespace = {
  readonly __typename: 'ImageNamespace';
  readonly name: Maybe<Scalars['String']>;
  readonly images: Maybe<ReadonlyArray<Maybe<IImage>>>;
};

export type IImage = {
  readonly __typename: 'Image';
  readonly digest: Maybe<Scalars['String']>;
  /** Image size in KB */
  readonly size: Maybe<Scalars['Float']>;
  readonly createdAt: Maybe<Scalars['String']>;
  readonly uploadedAt: Maybe<Scalars['String']>;
  readonly tags: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly commit: Maybe<IGithubCommit>;
  readonly deployer: Maybe<IDeployer>;
};

export type IGithubCommit = {
  readonly __typename: 'GithubCommit';
  readonly timestamp: Maybe<Scalars['String']>;
  readonly fullName: Maybe<Scalars['String']>;
  readonly avatarUrl: Maybe<Scalars['String']>;
  readonly username: Maybe<Scalars['String']>;
  readonly message: Maybe<Scalars['String']>;
  readonly prNumber: Maybe<Scalars['String']>;
};

export type IDeployer = {
  readonly __typename: 'Deployer';
  readonly email: Maybe<Scalars['String']>;
  readonly deployedAt: Maybe<Scalars['String']>;
};

export type IMutation = {
  readonly __typename: 'Mutation';
  /** (Unavailable for subscribers) Privileged mutation to allow cars to be added to a subscriber, untill consent system is finalized */
  readonly setImage: Maybe<Scalars['Boolean']>;
  /** Blocks a deployment */
  readonly blockDeployment: Maybe<Scalars['Boolean']>;
};


export type IMutationSetImageArgs = {
  environmentName: Scalars['String'];
  clusterName?: Maybe<Scalars['String']>;
  deploymentName: Scalars['String'];
  containerName: Scalars['String'];
  image: Scalars['String'];
  tag: Scalars['String'];
  namespace?: Maybe<Scalars['String']>;
  slackMessage?: Maybe<Scalars['String']>;
};


export type IMutationBlockDeploymentArgs = {
  deploymentName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  block: Scalars['Boolean'];
};

export type IBlockDeploymentMutationVariables = Exact<{
  deploymentName: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  block: Scalars['Boolean'];
}>;


export type IBlockDeploymentMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<IMutation, 'blockDeployment'>
);

export type ISetImageMutationVariables = Exact<{
  environmentName: Scalars['String'];
  deploymentName: Scalars['String'];
  containerName: Scalars['String'];
  image: Scalars['String'];
  tag: Scalars['String'];
  slackMessage?: Maybe<Scalars['String']>;
}>;


export type ISetImageMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<IMutation, 'setImage'>
);

export type IDeploymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type IDeploymentsQuery = (
  { readonly __typename: 'Query' }
  & { readonly infrastructure: Maybe<(
    { readonly __typename: 'Infrastructure' }
    & { readonly environments: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename: 'Environment' }
      & Pick<IEnvironment, 'name'>
      & { readonly deployments: Maybe<ReadonlyArray<Maybe<(
        { readonly __typename: 'Deployment' }
        & Pick<IDeployment, 'name' | 'generation' | 'updateStatus'>
        & { readonly containers: Maybe<ReadonlyArray<Maybe<(
          { readonly __typename: 'Container' }
          & Pick<IContainer, 'image' | 'name' | 'tag'>
        )>>>, readonly block: Maybe<(
          { readonly __typename: 'Block' }
          & Pick<IBlock, 'email' | 'blockedAt' | 'isBlocked' | 'message'>
        )> }
      )>>> }
    )>>>, readonly images: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename: 'ImageNamespace' }
      & Pick<IImageNamespace, 'name'>
      & { readonly images: Maybe<ReadonlyArray<Maybe<(
        { readonly __typename: 'Image' }
        & Pick<IImage, 'digest' | 'size' | 'createdAt' | 'uploadedAt' | 'tags'>
        & { readonly commit: Maybe<(
          { readonly __typename: 'GithubCommit' }
          & Pick<IGithubCommit, 'username' | 'avatarUrl' | 'fullName' | 'message' | 'prNumber'>
        )> }
      )>>> }
    )>>> }
  )> }
);


export const BlockDeploymentDocument = gql`
    mutation BlockDeployment($deploymentName: String!, $message: String, $block: Boolean!) {
  blockDeployment(
    deploymentName: $deploymentName
    message: $message
    block: $block
  )
}
    `;
export type IBlockDeploymentMutationFn = Apollo.MutationFunction<IBlockDeploymentMutation, IBlockDeploymentMutationVariables>;

/**
 * __useBlockDeploymentMutation__
 *
 * To run a mutation, you first call `useBlockDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockDeploymentMutation, { data, loading, error }] = useBlockDeploymentMutation({
 *   variables: {
 *      deploymentName: // value for 'deploymentName'
 *      message: // value for 'message'
 *      block: // value for 'block'
 *   },
 * });
 */
export function useBlockDeploymentMutation(baseOptions?: Apollo.MutationHookOptions<IBlockDeploymentMutation, IBlockDeploymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IBlockDeploymentMutation, IBlockDeploymentMutationVariables>(BlockDeploymentDocument, options);
      }
export type BlockDeploymentMutationHookResult = ReturnType<typeof useBlockDeploymentMutation>;
export type BlockDeploymentMutationResult = Apollo.MutationResult<IBlockDeploymentMutation>;
export type BlockDeploymentMutationOptions = Apollo.BaseMutationOptions<IBlockDeploymentMutation, IBlockDeploymentMutationVariables>;
export const SetImageDocument = gql`
    mutation SetImage($environmentName: String!, $deploymentName: String!, $containerName: String!, $image: String!, $tag: String!, $slackMessage: String) {
  setImage(
    environmentName: $environmentName
    deploymentName: $deploymentName
    containerName: $containerName
    image: $image
    tag: $tag
    slackMessage: $slackMessage
  )
}
    `;
export type ISetImageMutationFn = Apollo.MutationFunction<ISetImageMutation, ISetImageMutationVariables>;

/**
 * __useSetImageMutation__
 *
 * To run a mutation, you first call `useSetImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setImageMutation, { data, loading, error }] = useSetImageMutation({
 *   variables: {
 *      environmentName: // value for 'environmentName'
 *      deploymentName: // value for 'deploymentName'
 *      containerName: // value for 'containerName'
 *      image: // value for 'image'
 *      tag: // value for 'tag'
 *      slackMessage: // value for 'slackMessage'
 *   },
 * });
 */
export function useSetImageMutation(baseOptions?: Apollo.MutationHookOptions<ISetImageMutation, ISetImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ISetImageMutation, ISetImageMutationVariables>(SetImageDocument, options);
      }
export type SetImageMutationHookResult = ReturnType<typeof useSetImageMutation>;
export type SetImageMutationResult = Apollo.MutationResult<ISetImageMutation>;
export type SetImageMutationOptions = Apollo.BaseMutationOptions<ISetImageMutation, ISetImageMutationVariables>;
export const DeploymentsDocument = gql`
    query Deployments {
  infrastructure {
    environments {
      name
      deployments {
        name
        generation
        updateStatus
        containers {
          image
          name
          tag
        }
        block {
          email
          blockedAt
          isBlocked
          message
        }
      }
    }
    images {
      name
      images {
        digest
        size
        createdAt
        uploadedAt
        tags
        commit {
          username
          avatarUrl
          fullName
          message
          prNumber
        }
      }
    }
  }
}
    `;

/**
 * __useDeploymentsQuery__
 *
 * To run a query within a React component, call `useDeploymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeploymentsQuery(baseOptions?: Apollo.QueryHookOptions<IDeploymentsQuery, IDeploymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IDeploymentsQuery, IDeploymentsQueryVariables>(DeploymentsDocument, options);
      }
export function useDeploymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IDeploymentsQuery, IDeploymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IDeploymentsQuery, IDeploymentsQueryVariables>(DeploymentsDocument, options);
        }
export type DeploymentsQueryHookResult = ReturnType<typeof useDeploymentsQuery>;
export type DeploymentsLazyQueryHookResult = ReturnType<typeof useDeploymentsLazyQuery>;
export type DeploymentsQueryResult = Apollo.QueryResult<IDeploymentsQuery, IDeploymentsQueryVariables>;